<template>
    <v-container fluid class="mb-16">
        <v-card height="100%">
            <v-tabs color="#002441">
                <v-tab v-for="item in items" :key="item">
                    {{ item }}
                </v-tab>

                <!-- COD payment -->
                <v-tab-item>
                    <AllPayment />
                </v-tab-item>
                <!-- COD payment -->
                <v-tab-item>
                    <CodPayment />
                </v-tab-item>

                <!-- Online Payment Part -->
                <v-tab-item>
                    <v-container fluid>
                        <v-row>
                            <v-row>
                                <v-col>
                                    <template>
                                        <v-card>
                                            <v-row class="mt-5">
                                                <!-- Data download and print option  -->
                                                <v-col cols="4">
                                                    <v-card-title class="pb-10 ">
                                                        <!-- <v-spacer></v-spacer> -->
                                                        <v-btn @click="exportTableToCSV('online_payment.csv')" text>
                                                            <v-icon class="pr-5" large color="red">mdi-download
                                                            </v-icon>
                                                        </v-btn>
                                                        <v-btn text @click="print">
                                                            <v-icon class="pr-5" large color="red">mdi-printer
                                                            </v-icon>
                                                        </v-btn>
                                                    </v-card-title>
                                                </v-col>
                                                <!-- Date filtering  -->
                                                <v-col cols="8" class="d-flex flex-row">
                                                    <v-spacer></v-spacer>
                                                    <v-col cols="2">
                                                        <v-menu v-model="menu" :close-on-content-click="false"
                                                            :nudge-right="40" transition="scale-transition" offset-y
                                                            min-width="290px">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-text-field v-model="dateto1" label="From Date"
                                                                    prepend-icon="mdi-calendar" readonly v-bind="attrs"
                                                                    v-on="on" dense style="padding:0px !important">
                                                                </v-text-field>
                                                            </template>
                                                            <v-date-picker v-model="dateto1" @input="menu = false">
                                                            </v-date-picker>
                                                        </v-menu>
                                                    </v-col>
                                                    <v-col cols="2">
                                                        <v-menu v-model="menu2" :close-on-content-click="false"
                                                            :nudge-right="40" transition="scale-transition" offset-y
                                                            min-width="290px">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-text-field v-model="dateto" label="To Date"
                                                                    prepend-icon="mdi-calendar" readonly v-bind="attrs"
                                                                    v-on="on" dense style="padding:0px !important">
                                                                </v-text-field>
                                                            </template>
                                                            <v-date-picker v-model="dateto" @input="menu2 = false">
                                                            </v-date-picker>
                                                        </v-menu>
                                                    </v-col>
                                                    <v-btn text @click="seereport" class="mr-2 font-weight-bold" style="background: gray">
                                                        Submit
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                            <!-- </v-card>   -->
                                            <!-- Data table start  -->
                                            <div id="dataapp" class="mt-4">
                                                <v-data-table :headers="onlineaymentHeaders" :items="onlinePayment"
                                                    :search="search" :loading="loading"
                                                    :server-items-length="totalItems1" hide-default-footer
                                                    loading-text="Please wait..." item-key="id" class="elevation-1"
                                                    style="background: #f5f5f5">
                                                </v-data-table>
                                            </div>
                                            <!-- Data table end  -->
                                            <!-- Pagination Start  -->
                                            <div class="d-flex justify-content-center mt-5">
                                                <!-- <v-col cols="3" md="3"> -->
                                                <div class="col-4">
                                                    Total {{ totalItems1 }} records
                                                </div>
                                                <!-- </v-col> -->
                                                <!-- <v-col cols="3" md="3"> -->
                                                <div class="col-4">
                                                    <v-select dense outlined hide-details v-model="perPage1"
                                                        label="Items per page" @change="handlePageSizeChange"
                                                        :items="pageSizes1">
                                                    </v-select>
                                                </div>
                                                <div class="col-4">
                                                    <v-pagination v-model="currentPage1" :length="totalPages1"
                                                        total-visible="7" @input="handlePageChange">
                                                    </v-pagination>
                                                </div>
                                            </div>
                                            <!-- Pagination End  -->
                                        </v-card>
                                    </template>
                                </v-col>
                            </v-row>
                        </v-row>
                    </v-container>
                </v-tab-item>
            </v-tabs>
        </v-card>
    </v-container>
</template>
  
<script>
export default {
    data: () => ({
        items: ["All Payment","COD Payment", "Online Payment"],
        date: new Date().toISOString().substr(0, 2),
        expanded: [],
        search: "",
        perPage1: '10',
        currentPage1: 1,
        totalItems1: 0,
        totalPages1: 0,
        pageSizes1: ['10', '30', '50', '100', 'All'],
        singleExpand: false,
        loading: true,
        dateto1: null,
        dateto: null,
        menu2: false,
        menu: false,
        order_status: null,
        onlineaymentHeaders: [{
            text: "Order ID",
            align: "start",
            value: "id",
            sortable: false,
        },
        {
            text: " Order Date",
            value: "ordered_date",
            sortable: false
        },
        {
            text: "Customer Name",
            value: "billing_address.name",
            sortable: false
        },
        {
            text: "Mobile Number",
            value: "billing_address.phone_number",
            sortable: false
        },
        {
            text: "Location",
            value: "billing_address.location",
            sortable: false
        },
        {
            text: "Payment Type ",
            value: "payment_method",
            sortable: false
        },
        {
            text: "Price",
            value: "price_total",
            sortable: false
        },
        ],
        onlinePayment: [],
    }),

    components: {
        CodPayment: () => import("@/components/base/CodPayment"),
        AllPayment: () => import("@/components/base/AllPayment"),
    },

    methods: {
        handlePageSizeChange(event) {
            // console.log('page size change method called');
            // console.log('event = ', event);
            this.perPage1 = (event);
            this.loading = true;
            this.initialize();
        },

        handlePageChange(value) {
            // console.log('handle page change value = ', value);
            this.currentPage1 = value;
            this.loading = true;
            this.initialize();
            window.scrollTo(0, 0);
        },

        initialize() {
            let perPage;
            if (this.perPage1 === 'All') {
                perPage = this.totalItems1;
            }
            else {
                perPage = this.perPage1;
            }
            this.loading = true;
            axios.get(`Cart/payment-information-online-payment/?page=${this.currentPage1}&size=${perPage}&FromDate=${this.dateto1}&ToDate=${this.dateto}`).then((response) => {

                if (response.status === 200) {
                    // console.log('results = ', response.data.results);
                    this.onlinePayment = response.data.results;
                    // console.log('order s= ', this.onlinePayment);
                    this.totalItems1 = response.data.count;
                    this.totalPages1 = parseInt(this.totalItems1 / this.perPage1);
                    this.loading = false;
                }
            });

        },
        downloadCSV(csv, filename) {
            var csvFile;
            var downloadLink;

            csvFile = new Blob([csv], { type: 'text/csv' });
            downloadLink = document.createElement("a");
            downloadLink.download = filename;
            downloadLink.href = window.URL.createObjectURL(csvFile);
            downloadLink.style.display = "none";

            document.body.appendChild(downloadLink);
            downloadLink.click();
        },

        exportTableToCSV(filename) {
            var csv = [];
            var rows = document.querySelectorAll(" #dataapp table tr");

            for (var i = 0; i < rows.length; i++) {
                var row = [], cols = rows[i].querySelectorAll("td, th");
                for (var j = 0; j < cols.length; j++)
                    row.push(cols[j].innerText);
                csv.push(row.join(","));
            }

            this.downloadCSV(csv.join("\n"), filename);
        },
        print() {
            var divToPrint = document.getElementById("dataapp");
            var style = "<style>";
            style = style + "table {width: 100%;font: 17px Calibri;}";
            style =
                style +
                "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
            style = style + "padding: 2px 3px;text-align: center;}";
            style = style + "</style>";

            var win = window.open("", "", "height=1900,width=1900");
            win.document.write(style); //  add the style.
            win.document.write(divToPrint.outerHTML);
            win.document.close();
            win.print();
        },
        fromdate() { },
        seereport() {
            // console.log('this from  date is :', this.dateto1);
            // console.log('this to  date is :', this.dateto);
            this.initialize();
        },
        todate() { },
        getColor(admin_status) {
            if (admin_status == "Cancelled") return "red";
            else return "green";
        },
    },
    mounted() {
        this.initialize();

    },
};
</script>
  
<style>
.v-data-table>.v-data-table__wrapper>table>tbody>tr>th,
.v-data-table>.v-data-table__wrapper>table>thead>tr>th,
.v-data-table>.v-data-table__wrapper>table>tfoot>tr>th {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 1.2rem;
    height: 48px;
}
</style>
  